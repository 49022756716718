const HEADER_TABS = [
    {
        title: "about",
        listTitle: "Discover the people and passion behind TRooTech",
        blogTitle: "Insights",
    },
    {
        title: "technologies",
        listTitle: "All your technology needs solved under one roof",
        blogTitle: "Insights",
    },
    {
        title: "hire developers",
        listTitle: "Fully flexible on-demand software development teams for your projects",
        blogTitle: "Insights",
    },
    {
        title: "services",
        listTitle: "Transformative Digital Solutions: Custom Software Development, Enterprise Technology, and Industry-Specific Innovations",
        blogTitle: "Insights",
    },
    {
        title: "industries",
        listTitle: "Empowering Industries with Digital Excellence & Niche Expertise",
        blogTitle: "Insights",
    },
    {
        title: "startup",
        listTitle: "Startup domains we support",
        blogTitle: "Insights",
    },
    {
        title: "resources",
        listTitle: "Our research in development",
        blogTitle: "Insights",
    },
];

const SOCIAL_MEDIA_FOOTER_DATA = {
    "linked in": {
        name: "linkdin",
        imgUrl: "/images/foot-linkdin.svg",

    },
    instagram: {
        name: "instagarm",
        imgUrl: "/images/foot-instagarm.svg",
    },
    twitter: {
        name: "twiter",
        imgUrl: "/images/foot-twiter.svg",
    },
};

export { HEADER_TABS, SOCIAL_MEDIA_FOOTER_DATA }
