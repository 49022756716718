/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import QueryString from "qs";
import Link from "next/link";
import { useRouter } from "next/router";
import NextImage from "next/image";
import dynamic from "next/dynamic";
import { HEADER_TABS } from "../../utils/constants";

const LetsConnect = dynamic(() =>
	import("../../components/modals/LetsConnect")
);
const DesktopMenu = dynamic(() =>
	import("../../components/header/DesktopMenu")
);
const MobileMenu = dynamic(() => import("../../components/header/MobileMenu"));
const HeaderMobileBottom = dynamic(() =>
	import("../../components/header/HeaderMobileBottom")
);

React.useLayoutEffect = React.useEffect;

function Header({ pageSlug = "", isNew = false }) {

	const ONLY_ALPHANUMERIC_REGEX = /\W/g;
	const router = useRouter();
	const dispatch = useDispatch();
	const { slug = pageSlug } = router.query;

	// const isHomePage = router.pathname === '/'
	const isHomePage = false;
	const isNewBanner = router.asPath.includes("preview/technologies") ||
		router.asPath.includes("request-proposal") ||
		router.asPath.includes("hire-dedicated-developers") ||
		router.asPath.includes("preview/request-proposal-2") ||
		router.asPath.includes("contact-us") ||
		router.asPath.includes("/case-studies/") ||
		isHomePage ||
		isNew;

	// const isNewCase = router.asPath.includes("/case-studies/") || isNew;
	const isNewCase = router.asPath.includes("/case-studies/");
	const [show, setShow] = useState(false);
	const [isMore, setIsMore] = useState(false);
	const showModal = () => setShow(true);
	const hideModal = () => setShow(false);
	const [overlay, setOverlay] = useState(false);
	const [menuItemClass, setMenuItemClass] = useState("");
	const [lastActiveMenu, setLastActiveMenu] = useState(null);
	const [currentSelected, setCurrentSelected] = useState(0);
	const [header, setHeader] = useState();
	const [activeMenuTab, setActiveMenuTab] = useState({});
	// const isheader = useSelector((state) => state.headerReducer.isheader);
	const classSubmenu = "submenu";
	const toggleIsMore = () => setIsMore(!isMore)

	const [isDesktopView, setIsDesktopView] = useState(true);
	let baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
	let apiBaseUrl = process.env.NEXT_PUBLIC_REACT_APP_API_BASE_URL;


	const fetchHeader = async () => {
		// Construct the query string
		const query = QueryString.stringify(
			{
				populate: "deep,5",
			},
			{
				encodeValuesOnly: false,
			}
		);

		// Construct the URL
		const url = `${apiBaseUrl}header?${decodeURIComponent(query)}`;

		try {
			// Perform the fetch request
			const resp = await fetch(url, {
				next: { revalidate: 3600, cache: "force-cache" }
			});

			// Check if the response is okay (status code 200-299)
			if (!resp.ok) {
				throw new Error(`HTTP error! Status: ${resp.status}`);
			}

			// Parse the response data
			const list = await resp.json();
			// console.log("Fetched header data:", list); // Log the fetched data

			// Extract header attributes
			const headerData = list?.data?.attributes;

			// Update the state with the fetched header data
			setHeader(headerData);
		} catch (error) {
			// Log any errors that occur during the fetch operation
			console.error("Failed to fetch header data:", error);
			// Optionally, you can handle specific error cases or set an error state
			setHeader(null); // Or handle it however you prefer
		}
	};

	// const fetchHeader = async () => {
	// 	const query = QueryString.stringify(
	// 		{
	// 			populate: "deep,5",
	// 		},
	// 		{
	// 			encodeValuesOnly: false,
	// 		}
	// 	);
	// 	const resp = await fetch(
	// 		`${apiBaseUrl}header?${decodeURIComponent(query)}`,
	// 		{ next: { revalidate: 3600, cache: "force-cache" } }
	// 	);
	// 	let list = await resp.json();

	// 	var headerData = list?.data?.attributes;

	// 	setHeader(headerData);
	// };

	useEffect(() => {
		if (header && slug) {
			const startUps =
				header?.submenu
					?.find((menu) => menu.title === "For Startups")
					?.submenu?.map((ele) => ele.href) || [];
			dispatch({ type: "SET_STARTUP_SLUGS", payload: startUps });

			let activeSubmenu = {};
			let parentActiveHref = null
			let mainActiveEle = header.submenu.find((el) => {
				if (
					el?.href?.replace(ONLY_ALPHANUMERIC_REGEX, "") ===
					slug?.replace(ONLY_ALPHANUMERIC_REGEX, "")
				) {
					activeSubmenu = el;
				} else {
					el.submenu.find((ele) => {
						if (ele?.href?.includes(slug)) {
							activeSubmenu = ele;
						} else {
							ele.submenu.find((el) => {
								if (el?.href?.includes(slug)) {
									activeSubmenu = el;
									parentActiveHref = [null, '', '#'].includes(ele?.href) ? null : ele?.href
								}
							});
						}
					});
				}
				return !!activeSubmenu.href;
			});

			const activeMenuTab =
				mainActiveEle?.title && activeSubmenu?.href
					? { ...mainActiveEle, activeHref: activeSubmenu.href, parentActiveHref }
					: {};

			setActiveMenuTab(activeMenuTab);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug, header]);

	const getActiveClass = (url) => {
		if (!url) {
			return "";
		}
		if (url.includes("newsroom") && router.asPath.includes("newsroom")) {
			return "active";
		}
		if (
			url?.replace(ONLY_ALPHANUMERIC_REGEX, "") ===
			activeMenuTab.activeHref?.replace(ONLY_ALPHANUMERIC_REGEX, "")
		) {
			return "active";
		}
		if (url?.replace(ONLY_ALPHANUMERIC_REGEX, "") ===
			(!activeMenuTab.parentActiveHref ? activeMenuTab.parentActiveHref : activeMenuTab.parentActiveHref.replace(ONLY_ALPHANUMERIC_REGEX, ""))) {
			return "active";
		}
		return "";
	};

	const getActiveData = (subMenuList = []) => {
		const activeData = subMenuList.length > 0 && subMenuList?.find((element) => {
			if (element.href == 'newsroom')
				return router.asPath == element.href
			else {
				if ((!element.href ? element.href : element.href?.replace(ONLY_ALPHANUMERIC_REGEX, "")) ===
					activeMenuTab.activeHref?.replace(ONLY_ALPHANUMERIC_REGEX, "")) {
					return true
				} else {
					return element.submenu.some((el) =>
						(!el.href ? el.href : el.href.replace(ONLY_ALPHANUMERIC_REGEX, "")) === activeMenuTab.activeHref?.replace(ONLY_ALPHANUMERIC_REGEX, "")
					)
				}
			}
		})
		if (
			Object.keys(activeData || {}).length > 0
		) {
			return activeData
		}
		return subMenuList[0];
	}

	useEffect(() => {
		fetchHeader();
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const setView = () => {
			if (window?.innerWidth > 996) {
				setIsDesktopView(true);
			} else {
				setIsDesktopView(false);
			}
		};
		setView();

		window.addEventListener("resize", setView);
		return () => {
			window.addEventListener("resize", setView);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSticky = () => {
		const header = document.querySelector(".header");
		const scrollTop = window.scrollY;
		scrollTop >= 100
			? header?.classList?.add("is-sticky")
			: header?.classList?.remove("is-sticky");
	};

	const handleMouseOver = (index) => {
		document.getElementById(`submenu-${index}`).removeAttribute("style");
		setLastActiveMenu(index);
		setMenuItemClass(classSubmenu);
		setOverlay(true);
	};

	const handleMouseOut = () => {
		setOverlay(false);
		setIsMore(false)
		setMenuItemClass("");
	};

	const handleMenuClick = (index, element) => {
		setOverlay(false);
		setIsMore(false)
		setMenuItemClass("");
		// document
		// 	.getElementById(`submenu-${index}`)
		// 	.style.setProperty("display", "none", "important");
		// setCurrentSelected(element.id);
		// setOverlay(false);
	};

	const handleMainMenuClick = (index, href = null) => {
		if (href) {
			document
				.getElementById(`submenu-${index}`)
				.style.setProperty("display", "none", "important");
			setOverlay(false);
		}
	};

	const onMobileMenu = () => {
		document
			.getElementById("responsive-navbar-nav")
			.style.removeProperty("show");

		document
			.getElementById("close-btn")
			.style.setProperty("navbar-toggler collapsed");
	};

	const handleMenuItemClick = (index, event) => {
		const hasSubmenuClass = document
			.getElementById(`submenu-${index}`)
			?.className?.includes(classSubmenu);
		if (hasSubmenuClass && index === lastActiveMenu) {
			setMenuItemClass("");
			setOverlay(false);
		} else {
			setMenuItemClass(classSubmenu);
			setOverlay(true);
		}
	};

	const getTitles = (currentTitle, key) => {
		const title = currentTitle.toLowerCase();
		const currentTab = HEADER_TABS.find((tab) => title?.includes(tab.title));
		if (!!currentTab && !!currentTab && !!key) {
			return currentTab[key];
		}
		return "Get to know us better";
	};

	const headerLogo = header?.logo?.data?.attributes?.url?.replace(
		"uploads",
		"uploads/f_webp"
	);

	const headerLogo1 = "/images/white-logo.svg";

	const headerLogoAlt = header?.logo?.data.attributes.alt;

	return header ? (
		<>
			<header
				className={`header header-menu ${isNewBanner ? "header-home" : ""} ${isNewCase ? "header-case" : ""}`}
			>
				<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
					<Container>
						<Link href="/" className="navbar-brand">
							<NextImage
								height={45}
								width={130}
								loading="lazy"
								src={`${baseUrl}${headerLogo}`}
								alt={`${baseUrl}${headerLogoAlt}`}
							/>
							<NextImage
								height={45}
								width={130}
								loading="lazy"
								src={headerLogo1}
								alt="Logo"
							/>
						</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse
							className="collapse1 sidenav-new-menu"
							id="responsive-navbar-nav"
						>
							<Navbar.Toggle
								aria-controls="responsive-navbar-nav nav-close"
								id="close-btn"
							/>
							{isDesktopView ? (
								<DesktopMenu
									menuDetails={header?.submenu}
									activeMenuTab={activeMenuTab}
									menuItemClass={menuItemClass}
									handleMouseOver={handleMouseOver}
									handleMouseOut={handleMouseOut}
									handleMenuItemClick={handleMenuItemClick}
									handleMainMenuClick={handleMainMenuClick}
									getTitles={getTitles}
									handleMenuClick={handleMenuClick}
									getActiveClass={getActiveClass}
									showModal={showModal}
									toggleIsMore={toggleIsMore}
									isMore={isMore}
									getActiveData={getActiveData}
								/>
							) : (
								<MobileMenu
									headerLogo={headerLogo}
									headerLogoAlt={headerLogoAlt}
									menuDetails={header?.submenu}
									activeMenuTab={activeMenuTab}
									getActiveClass={getActiveClass}
									onMobileMenu={onMobileMenu}
								/>
							)}
							<HeaderMobileBottom />
						</Navbar.Collapse>
					</Container>
				</Navbar>
				<div className={`menu-overlay ${overlay ? "" : "d-none"}`}></div>
			</header>
			<LetsConnect show={show} hide={hideModal} />
		</>
	) : (
		<></>
	);
}

export default Header;
